import React from 'react'
import { graphql } from 'gatsby'
import PortableText from '../lib/portableText'
import { Seo } from '../components/seo'

export const query = graphql`
  query BasicPageTemplateQuery($id: String!) {
    post: sanityBasicPage(id: { eq: $id }) {
      id
      _rawBody
      description {
        en
        et
        fi
      }
      title {
        en
        et
        fi
      }
    }
  }
`

const BasicPageTemplate = (props) => {
  const {
    data: { post },
    location,
  } = props

  let lang = props.pageContext.language
  let _rawBody = post._rawBody[lang]
  let title = post.title[lang]
  const metas = [
    {
      property: 'og:type',
      content: 'article',
    },
  ]
  return (
    <>
      <Seo title={title} language={lang} location={location} description={post.description} metas={metas} />
      <main className="main">
        <div className="archive-block">
          <div className="container container-sm">
            <div className="row">
              <div className="col-sm-12">
                <div className="container">
                  <PortableText blocks={_rawBody} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default BasicPageTemplate
